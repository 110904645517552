<template>
  <div class="lot-area-container">
    <Accordion
      v-if="step !== 5"
      :title="$t('details_area.lot_title')"
      :showArrow="this.isEmptyList(currentLotInfo) && !this.handleScreenWidth"
      :lgScreen="this.handleScreenWidth"
    >
      <LotInformation :currentLotInfo="currentLotInfo"></LotInformation>
    </Accordion>
    <Accordion
      v-if="step !== 5"
      :title="$t('lot_area.lots')"
      :showArrow="this.isEmptyList(lots) && !this.handleScreenWidth"
      :lgScreen="this.handleScreenWidth"
      :scrollable="true"
      @created="scrollToElement"
    >
      <tbody class="spacing" id="scroller">
        <tr
          v-for="lot in lots"
          :key="lot.index"
          v-bind:class="{ on: lot.id === currentLotId }"
          v-bind:id="lot.id"
          @click="goToLot(lot)"
          class="item"
        >
          <td class="num">#{{ lot.position }}</td>
          <td class="appellation">
            {{ lot.appellation }}
          </td>

          <td class="pointer">
            <img
              :src="require(`@/assets/icons/arrow-up-right-from-square.svg`)"
              class="icon"
            />
          </td>
        </tr>
      </tbody>
    </Accordion>
    <Accordion
      v-if="step !== 5 || (step === 5 && this.atLeastOneLotWon)"
      :title="$t('lot_area.bids')"
      :subtitle="$t('lot_area.bids_next')"
      :showArrow="this.isEmptyList(lotsWon) && !this.handleScreenWidth"
      :lgScreen="this.handleScreenWidth"
      :class="accordionEndStepStyle"
    >
      <tbody class="spacing">
        <tr
          class="pointer"
          v-for="lot in lotsWon"
          :key="lot.index"
          v-bind:id="lot.id"
          @click="goToLot(lot)"
        >
          <td class="num">#{{ lot.position }}</td>
          <td class="appellation">
            {{ lot.appellation }}
          </td>
          <td class="prix">{{ lot.price }} {{ catalogCurrency }}</td>
        </tr>
      </tbody>
    </Accordion>
  </div>
</template>
<script>
import _ from 'lodash';
import Accordion from '@/components/Accordion/Accordion.vue';
import LotInformation from '@/components/LotInformation/LotInformation';

export default {
  name: 'LotArea',
  components: {
    Accordion,
    LotInformation,
  },
  data() {
    return {
      lgScreenSize: 1200,
      windowWidth: window.innerWidth,
    };
  },
  props: {
    lots: Array,
    currentLotId: Number,
    lotsWon: Array,
    currentLotInfo: Object,
  },
  updated() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  methods: {
    isEmptyList(list) {
      return !_.isEmpty(list);
    },
    scrollToElement() {
      if (this.currentLotInfo.id) {
        const scroller = document.querySelector('#scroller');
        const item = document.getElementById(this.currentLotInfo.id);
        scroller.scrollTop = item.offsetTop;
      }
    },
    goToLot(lot) {
      this.$store.commit('setCurrentDisplayedLotId', lot.id);
    },
  },
  computed: {
    catalogCurrency() {
      return this.$store.state.catalogCurrency;
    },
    step() {
      return this.$store.state.step;
    },
    atLeastOneLotWon() {
      return this.$store.getters.myLotsWon.length >= 1;
    },
    accordionEndStepStyle() {
      return {
        endStepStyle: this.$store.state.step === 5,
      };
    },
    handleScreenWidth() {
      return this.windowWidth >= this.lgScreenSize;
    },
  },
  mounted() {
    this.scrollToElement();
  },
};
</script>
<style scoped>
.item:hover {
  background-color: #e9e9e9;
}
.icon {
  width: 13px;
  margin-top: -3px;
}
.pointer {
  text-align: right;
  padding-right: 10px !important;
}
.num {
  text-align: center;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 1);
  font-family: 'Lato Bold';
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0;
}
.appellation {
  font-family: 'Lato Light';
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0;
  text-align: left;
  text-transform: uppercase;
}
#scroller {
  overflow: scroll;
  height: 180px !important;
  width: 100%;
}
.spacing {
  padding-left: 10px;
  padding-right: 10px;
  display: block;
}
td,
tr {
  width: 100%;
}
</style>
